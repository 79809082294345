import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AppContext } from "../../App";
import LmsAPI from "@lipihipi/rtc-lms-client-sdk";
import { ActionType } from "../../Reducer";

export const StatusButton = () => {
  const { state, dispatch } = React.useContext<any>(AppContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const statuses: any = {
    Online: "Online",
    WashroomBreak: "Washroom Break",
    MeetingWithSupervisor: "Meeting With Supervisor",
    LunchBreak: "Lunch Break",
    Feedback: "Feedback",
    ITIssue: "IT Issue",
    TrainingSession: "Training Session",
    Offline: "Offline",
  }

  const handleClose = (status: string) => {
    setAnchorEl(null);
    if (!state.user?.isSuperAdmin) {
      LmsAPI.users.changeStatus({ currentStatus: status }).then((resp: any) => {
        LmsAPI.users.me().then(({ data }: any) => {
          dispatch({
            type: ActionType.GET_USER_SUCCESS,
            payload: data.data,
          });
        });
      });
    }
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {statuses[state.user?.currentStatus]}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {Object.keys(statuses).map((status) => (
          <MenuItem onClick={() => handleClose(status)}>{statuses[status]}</MenuItem>
        ))}
      </Menu>
    </div>
  );
};
